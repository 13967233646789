<template>
  <div class="companies">
    <SeparatorTitle :separator="false" :title="$route.name" />
    <SubMenu :items="items" />
  </div>
</template>

<script>
import SubMenu from "../../components/menu/SubMenu.vue";
import SeparatorTitle from "../../widgets/title/SeparatorTitle.vue";
import store from "../../store";
import { onBeforeMount } from "vue";
import { useRoute } from "vue-router";

export default {
  components: {
    SubMenu,
    SeparatorTitle,
  },
  setup() {
    const route = useRoute();
    const items = [
      { label: "Administradoras", to: "/administradoras/listado" },
    ];

    onBeforeMount(() => {
      const breadcrumbs = [
        { label: "Inicio", to: "/home" },
        { label: route.name, to: route.path },
      ];
      store.commit("setBreadcrumbs", breadcrumbs);
    });

    return { items };
  },
};
</script>

<style scoped>
.companies {
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}
</style>
